import React, { useState, useEffect, useContext } from 'react';
import { Waypoint } from 'react-waypoint';

import { GlobalContext } from '../context/GlobalContext';

const PageHero = props => {

  // const titleRef    = React.createRef();
  const subTitleRef = React.createRef();

  const context = useContext(GlobalContext);

  // const titleRefEnter = (event) => {
  //   titleRef.current.classList.add('el-in-view');
  // }

  const [hero, setHero] = useState({
    title: null,
    image: null,
    overlay: null,
    opacity: null,
  });

  useEffect(() => {

    if(context.apiData.hero) {
      setHero({
        title: context.apiData.hero.heading,
        image: context.apiData.hero.image.url,
        overlay: context.apiData.hero.overlay_color,
        opacity: context.apiData.hero.overlay_opacity,
      });
    }
  }, [context.apiData.hero]);

  const subTitleRefEnter = (event) => {
    subTitleRef.current.classList.add('el-in-view');
  }

  const printHtml = (html) => {
    if(html) {
      return html.split('<br>').map((w,i) => {
        return <div key={i}>{w}</div>
      });
    }
  }

  return (
    <section className="page-hero Nvl-hero-wrap" id="Home">
      <div
        className="Nvl-hero-bg"
        style={{backgroundImage: `url(${hero.image})`}}
      ></div>

      <div
        className="Nvl-hero-bg-overlay"
        style={{background: hero.overlay, opacity: hero.opacity}}></div>

      <div className="hero-inner">
        <div className="Nvl-hero-content">
          {/* <Waypoint
            scrollableAncestor={window}
            onEnter={titleRefEnter}>
            <h3 ref={titleRef} className="Nvl-hero-subtitle">We serve the taste of India</h3>
          </Waypoint> */}
          <Waypoint
            scrollableAncestor={window}
            onEnter={subTitleRefEnter}>
            <h1 ref={subTitleRef} className="Nvl-hero-title">{printHtml(hero.title)}</h1>
          </Waypoint>
        </div>
      </div>
    </section>
  )
}

export default PageHero;
