import React, { useRef, useContext, useEffect } from 'react';
import { GlobalContext } from '../context/GlobalContext';

const Masthead = () => {

  const context = useContext(GlobalContext);

  const orderModalRef = useRef(null);
  const orderLim = useRef(null);
  const bookLim = useRef(null);
  const orderPis = useRef(null);
  const bookPis = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      console.log('fired')
      var url = new URL((window.location.href).toLowerCase());

      if( url.searchParams.get('a') === 'order-limassol' ) {
        orderLim.current.click();
      }

      if( url.searchParams.get('a') === 'book-limassol' ) {
        bookLim.current.click();
      }

      if( url.searchParams.get('a') === 'order-pissouri' ) {
        orderPis.current.click();
      }

      if( url.searchParams.get('a') === 'book-pissouri' ) {
        bookPis.current.click();
      }
    }, 900);
  }, []);

  const closeOrderModal = (e) => {
      e.preventDefault();

      if(e.target === orderModalRef.current) {
        context.closeOrderModal();
      }
  }

  return (
    <div className="Nvl-masthead Nvl-container">
      <a href="#Home" style={{display: 'block'}}>
        <img className="masthead-logo" src="/assets/images/logo-006.svg" height="50" alt="Saffron logo" />
      </a>

      <nav className="Nvl-main-nav">
        <ul className="Nvl-nav-list">
          <li className="Nvl-nav-list-item Nvl-list-i1">
            <a href="#Home" className="Nvl-nav-list-link">Home</a>
          </li>
          <li className="Nvl-nav-list-item Nvl-list-i2">
            <a href="#Locations" className="Nvl-nav-list-link">Locations</a>
          </li>
          <li className="Nvl-nav-list-item Nvl-list-i2">
            <a href="#Menus" className="Nvl-nav-list-link">Our Menus</a>
          </li>
          <li className="Nvl-nav-list-item Nvl-list-i3">
            <a href="#Gallery" className="Nvl-nav-list-link">Photos</a>
          </li>
          <li className="Nvl-nav-list-item Nvl-list-i4">
            <a href="#Contact" className="Nvl-nav-list-link">Contact</a>
          </li>
          <li className="Nvl-nav-list-item Nvl-list-i5">
            {/* <a
              href="https://foody.com.cy/gr/menu/saffron"
              className="Nvl-nav-list-link Nvl-nav-list-link-cta z-depth-3"
              target="_blank" rel="noopener noreferrer"></a> */}
              <button
                className="Nvl-nav-list-link Nvl-nav-list-link-cta z-depth-2"
                type="button"
                onClick={() => context.openOrderModal()}>
                <i className="akk-icon-food"></i> ORDER ONLINE / BOOK TABLE
              </button>
          </li>
        </ul>
      </nav>
      {/* <a href="#Order" onClick={() => context.openOrderModal()} className="Nvl-nav-list-link Nvl-order-online">ORDER ONLINE</a> */}

      <div
        className={`modal ${context.orderModal ? 'modal-open' : 'modal-closed'}`}
        onClick={(e) => closeOrderModal(e)}
        ref={orderModalRef}>
        <section className="modal-main">
          <button className="order-modal-close-btn" onClick={() => context.closeOrderModal()}>X</button>
          <div className="order-modal">
            <h1>Choose your location</h1>
            <div className="location-btns">
              <button
                onClick={() => context.setOrderLocation('lim')}
                className={context.orderLocation === 'lim' ? 'selected-loc' : 'lim-loc'}>LIMASSOL</button>
              <button
                onClick={() => context.setOrderLocation('pis')}
                className={context.orderLocation === 'pis' ? 'selected-loc' : 'pis-loc'}>PISSOURI</button>
            </div>
              <hr/>
            <div className="loc-order-btns-wrap">
              <div className={context.orderLocation === 'lim' ? 'loc-order-btns' : 'loc-order-btns hidden-loc-btns'} >
                <p>LIMASSOL</p>
                <button
                  ref={orderLim}
                  className="Nvl-nav-list-link Nvl-nav-list-link-cta z-depth-3 Nvl-btn-order njs-order-online-btn njs-order-online-btn-limassol"
                  data-glf-cuid="5cc06166-70f2-48d0-8dc2-f5961d14f50f"
                  data-glf-ruid="d68ed79c-bf90-463c-acdc-dd4cea8c8964"
                ><i className="akk-icon-bag"></i> ORDER ONLINE</button>
                <button
                ref={bookLim}
                  className="Nvl-nav-list-link Nvl-nav-list-link-cta z-depth-3 Nvl-btn-order njs-book-table-btn njs-book-table-btn-limassol"
                  data-glf-cuid="5cc06166-70f2-48d0-8dc2-f5961d14f50f"
                  data-glf-ruid="d68ed79c-bf90-463c-acdc-dd4cea8c8964"
                  data-glf-reservation="true"
                ><i className="akk-icon-food"></i> BOOK A TABLE</button>
              </div>
            </div>
            <div className={context.orderLocation === 'pis' ? 'loc-order-btns' : 'loc-order-btns hidden-loc-btns'}>
                <p>PISSOURI</p>
                <button
                  ref={orderPis}
                  className="Nvl-nav-list-link Nvl-nav-list-link-cta z-depth-3 Nvl-btn-order njs-order-online-btn njs-order-online-btn-pissouri"
                  data-glf-cuid="5cc06166-70f2-48d0-8dc2-f5961d14f50f"
                  data-glf-ruid="897b7dd6-cd05-4950-98f1-95c2e253eefc"
                ><i className="akk-icon-bag"></i> ORDER ONLINE</button>
                <button
                ref={bookPis}
                  className="Nvl-nav-list-link Nvl-nav-list-link-cta z-depth-3 Nvl-btn-order njs-book-table-btn njs-book-table-btn-pissouri"
                  data-glf-cuid="5cc06166-70f2-48d0-8dc2-f5961d14f50f"
                  data-glf-ruid="897b7dd6-cd05-4950-98f1-95c2e253eefc"
                  data-glf-reservation="true"
                ><i className="akk-icon-food"></i> BOOK A TABLE</button>
              </div>
            </div>
        </section>
      </div>
    </div>
  )
}

export default Masthead;
