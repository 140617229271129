import React, { Component } from 'react';
import Modal from 'react-awesome-modal';

import { loadScript, restaurantLocations, colors } from '../helpers';
import pin from '../assets/images/pin.png';

export default class LocationMap extends Component {

  state = {
    modalVisible: false,
    modalContent: ''
  }

  openModal() {
    this.setState({
      modalVisible : true
    });
  }

  closeModal() {
      this.setState({ modalVisible : false });
  }

  setModalContent = (content) => {
    this.setState({ modalContent: content });
  }

  initMap = () => {
    let map = new window.google.maps.Map(document.getElementById('map-canvas'), {
      center: {lat: 35.0771662, lng: 33.2361444},
      zoom: 10,
      styles: [
        {
          "featureType": "administrative",
          "elementType": "all",
          "stylers": [
              {
                  "visibility": "simplified"
              }
          ]
      },
      {
          "featureType": "landscape",
          "elementType": "geometry",
          "stylers": [
              {
                  "visibility": "simplified"
              },
              {
                  "color": "#fcfcfc"
              }
          ]
      },
      {
          "featureType": "poi",
          "elementType": "geometry",
          "stylers": [
              {
                  "visibility": "simplified"
              },
              {
                  "color": "#fcfcfc"
              }
          ]
      },
      {
          "featureType": "road.highway",
          "elementType": "geometry",
          "stylers": [
              {
                  "visibility": "simplified"
              },
              {
                  "color": "#dddddd"
              }
          ]
      },
      {
          "featureType": "road.arterial",
          "elementType": "geometry",
          "stylers": [
              {
                  "visibility": "simplified"
              },
              {
                  "color": "#dddddd"
              }
          ]
      },
      {
          "featureType": "road.local",
          "elementType": "geometry",
          "stylers": [
              {
                  "visibility": "simplified"
              },
              {
                  "color": "#eeeeee"
              }
          ]
      },
      {
          "featureType": "water",
          "elementType": "geometry",
          "stylers": [
              {
                  "visibility": "simplified"
              },
              {
                  "color": "#dddddd"
              }
          ]
      }
      ]
    });

    let bounds = new window.google.maps.LatLngBounds();
    // let infowindow = new window.google.maps.InfoWindow();

    for (let i = 0; i < restaurantLocations.length; i++) {
      let rest = restaurantLocations[i];
      var marker = new window.google.maps.Marker({
        position: {lat: rest.lat, lng: rest.lng},
        map: map,
        icon: pin,
        title: rest.name,
      });

      bounds.extend(marker.position);

      window.google.maps.event.addListener(marker, 'click', ((marker, i) => {
        return () => {
          // infowindow.setContent(infoContent);
          // infowindow.open(map, marker);
          this.setModalContent(
            <div className="Nvl-x">
              <h2 className="Nvl-txt-center">{rest.name}</h2>
              <p className="Nvl-txt-center"><i style={{color: colors.red}}className="akk-icon-location"></i> {rest.location}</p>
              <p>
                <a href={rest.map} className="Nvl-btn Nvl-btn-link"><i className="akk-icon-map" target="_blank" rel="noopener noreferrer">
                  </i> Get directions
                </a>
              </p>
            </div>
          );
          this.setState({ modalVisible: true });
        };
      })(marker, i));
    }

    map.fitBounds(bounds);

    // Resize stuff...
    window.google.maps.event.addDomListener(window, "resize", function() {
      let center = map.getCenter();
      window.google.maps.event.trigger(map, "resize");
      map.setCenter(center);
    });
  }

  loadMapScript = () => {
    loadScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyBUqsN5mBOh0TTSEe6zLq5DqJCUMN8rcJM&callback=initMap');
    window.initMap = this.initMap;
  }

  componentDidMount() {
    this.loadMapScript();
  }

  render() {
    return (
      <section
        id="location-map">
        <div id="map-canvas"></div>
        <Modal
          visible={this.state.modalVisible}
          width="340"
          effect="fadeInUp"
          onClickAway={() => this.closeModal()}>
            <div className="map-location-modal z-depth-3">
              {this.state.modalContent}
            </div>
        </Modal>
      </section>
    )
  }
}
