import React, {useContext} from 'react';
import { GlobalContext } from '../../context/GlobalContext';

const DefaultLayout = props => {
  const context = useContext(GlobalContext);
    return (
      <div className="nvl-layout nvl-layout-default">
          <header className="nvl-layout-masthead-wrapper">
            {props.masthead}
            <button
              className="Nvl-btn-order Nvl-btn-order-sm"
              type="button"
              onClick={() => context.openOrderModal()}>
              <i className="akk-icon-food"></i> ORDER ONLINE / BOOK TABLE
            </button>
          </header>
          <div className="nvl-layout-content-wrapper">
            {props.content}
          </div>
          <div className="nvl-layout-footer-wrapper">
            {props.footer}
          </div>
      </div>
    );
}

export default DefaultLayout;
