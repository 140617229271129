import React, { useRef, useContext, useState, useEffect } from 'react';
import { PhotoSwipe } from 'react-photoswipe';
import 'react-photoswipe/lib/photoswipe.css';
import fjGallery from 'flickr-justified-gallery';
import { GlobalContext } from '../context/GlobalContext';


const PhotoGallery = () => {

  const photosRef = useRef(null);

  const context = useContext(GlobalContext);

  const [galleryStatus, setGalleryStatus] = useState(false);
  const [photoIdx, setPhotoIdx] = useState(0);
  const [photos, setPhotos] = useState([]);

  const openGallery = (idx) => {
    setPhotoIdx(idx);
    setGalleryStatus(true);
  }

  useEffect(() => {
    const photoArray = [];

    if(context.apiData.gallery) {
      context.apiData.gallery.forEach((photo) => {
        photoArray.push({
          src: photo.url,
          w: photo.width, h: photo.height,
          th: photo.sizes.medium_large
        });
      });

      setPhotos(photoArray);
    }
  }, [context.apiData.gallery]);

  useEffect(() => {
    if(photosRef.current.children.length) {
      fjGallery(photosRef.current, {
        itemSelector: '.fj-gallery-item',
        rowHeight: 240,
        gutter: 20
      });
    }
  }, [photos]);

  return (
    <section id="Gallery">
      <div id="photo-gallery" ref={photosRef}>
        {photos && photos.map((p, i) =>
          <div className="fj-gallery-item" key={i}>
            <div className="fj-gallery-item-x z-depth-3">
              <img className="Nvl-img-flex" src={p.th} alt=""/>
              <div className="fj-gallery-overlay" onClick={() => openGallery(i)}></div>
            </div>
          </div>
        )}
      </div>
      <PhotoSwipe
        isOpen={galleryStatus}
        items={photos}
        options={{index: photoIdx}}/>
      {/* <ReactBnbGallery
        show={this.state.galleryOpened}
        photos={this.state.photos}
        onClose={this.closeGallery}
        activePhotoIndex={this.state.photoIndex}/> */}
    </section>
  );
}

export default PhotoGallery;
