import React from 'react';

const Footer = props => {

  return (
    <footer id="Nvl-footer">
      <div className="footer-grid" id="Contact">
        <div className="sm-txt-c">
          <img width="220" src="/assets/images/logo-new.png" alt="Sffron Cyprus logo"/>
        </div>

        <div>
          <h3>Saffron Indian Limassol</h3>
          <p><i className="akk-icon-location"></i> Eleftherias 50, 3042 Limassol, Cyprus</p>
          <p>
            <a href="tel:+35796293737" className="Nvl-btn Nvl-btn-link">
              <i className="akk-icon-phone"></i> <span style={{color: '#640d0f'}}>+357 96 293 737</span>
            </a>
          </p>
          <p>
          <a href="mailto:contact@saffroncyprus.com" className="Nvl-btn Nvl-btn-link">
              <i className="akk-icon-mail"></i> <span style={{color: '#640d0f'}}>contact@saffroncyprus.com</span>
            </a>
          </p>

          <ul className="footer-contact-links">
            <li className="Nvl-txt-center">
              <a
                href="https://m.me/SaffronLimassol"
                target="_blank"
                rel="noopener noreferrer"
                className="njs-social-link njs-social-link-limassol-messenger">
                <i className="akk-icon-messenger"></i>
              </a>
            </li>
            <li className="Nvl-txt-center">
              <a
                href="https://www.facebook.com/SaffronLimassol/"
                target="_blank"
                rel="noopener noreferrer"
                className="njs-social-link njs-social-link-limassol-facebook">
                <i className="akk-icon-facebook"></i>
              </a>
            </li>
            <li className="Nvl-txt-center">
              <a
                href="https://www.instagram.com/saffronlimassol/"
                target="_blank"
                rel="noopener noreferrer"
                className="njs-social-link njs-social-link-limassol-instagram">
                <i className="akk-icon-instagram"></i>
              </a>
            </li>
            <li className="Nvl-txt-center">
              <a
                href="https://www.tripadvisor.com/Restaurant_Review-g190382-d13278190-Reviews-Saffron_Indian-Limassol_Limassol_District.html"
                target="_blank"
                rel="noopener noreferrer"
                className="njs-social-link njs-social-link-limassol-tripadvisor">
                <i className="akk-icon-tripadvisor"></i>
              </a>
            </li>
          </ul>
        </div>

        <div>
          <h3>Saffron Indian Pissouri</h3>
          <p><i className="akk-icon-location"></i> 64 Ampelonon Street, 4607 Pissouri bay, Cyprus </p>
          <p>
            <a href="tel:+35796778084" className="Nvl-btn Nvl-btn-link">
              <i className="akk-icon-phone"></i> <span style={{color: '#640d0f'}}>+357 96 778 084</span>
            </a>
          </p>
          <p>
          <a href="mailto:contact@saffroncyprus.com" className="Nvl-btn Nvl-btn-link">
              <i className="akk-icon-mail"></i> <span style={{color: '#640d0f'}}>contact@saffroncyprus.com</span>
            </a>
          </p>

          <ul className="footer-contact-links">
            <li className="Nvl-txt-center">
              <a
                href="https://m.me/SaffronPissouri"
                target="_blank"
                rel="noopener noreferrer"
                className="njs-social-link njs-social-link-pissouri-messenger">
                <i className="akk-icon-messenger"></i>
              </a>
            </li>
            <li className="Nvl-txt-center">
              <a
                href="https://www.facebook.com/SaffronPissouri/"
                target="_blank"
                rel="noopener noreferrer"
                className="njs-social-link njs-social-link-pissouri-facebook">
                <i className="akk-icon-facebook"></i>
              </a>
            </li>
            <li className="Nvl-txt-center">
              <a
                href="https://www.instagram.com/saffronpissouri/"
                target="_blank"
                rel="noopener noreferrer"
                className="njs-social-link njs-social-link-pissouri-instagram">
                <i className="akk-icon-instagram"></i>
              </a>
            </li>
            <li className="Nvl-txt-center">
              <a
                href="https://www.tripadvisor.com/Restaurant_Review-g616081-d4496566-Reviews-Saffron_Contemporary_Indian_Cuisine-Pissouri_Limassol_District.html"
                target="_blank"
                rel="noopener noreferrer"
                className="njs-social-link njs-social-link-pissouri-tripadvisor">
                <i className="akk-icon-tripadvisor"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="gdpr-links">
        <a href="#Home">PRIVACY POLICY</a>
        <span> | </span>
        <a href="#Home">COOKIES</a>
      </div>

      <div className="Nvl-copyright">
        <div>
          Saffron Cyprus &copy; 2019
        </div>
        <span> | </span>
        <div>
          Powered by <a href="https://noveldigital.pro" target="_blank" rel="noopener noreferrer"><img src="/assets/images/novel.png" alt="Novel Digital Agency Cyprus" /></a>
        </div>
      </div>
    </footer>
  )
}

export default Footer;
