import React from 'react';
import { Waypoint } from 'react-waypoint';

const WelcomeSection = () => {

  let txtRef = React.createRef();

  const onTxtEnter = (event) => {
    txtRef.current.classList.add('el-in-view');
  }

  return (
    <section className="Nvl-section Nvl-section-welcome" id="Welcome">
      <div className="Nvl-container">
        <div className="Nvl-welcome">
          <div className="Nvl-welcome-content">
            <img className="Nvl-img-flex" src="/assets/images/welcome.png" alt="Indian cuisine"/>
          </div>

          <div className="welcome-txt-wrap z-depth-3">
            <Waypoint
              scrollableAncestor={window}
              onEnter={onTxtEnter}>

              <div ref={txtRef} className="sm-txt-c welcome-txt">
                <h3 className="Nvl-section-title sm-txt-c">WELCOME TO <br/><span>SAFFRON</span> INDIAN RESTAURANTS</h3>
                <p>Cosy up with a classy curry. Here at Saffron, we'll warm you up from the inside,
                so you'll never want to leave! Come in and let our magical spices help you relax.
                We serve Contemporary Indian Cuisine with a strong accent of authentic way of cooking to
                delight our patrons’ taste with a variety of meals from vegetarian to delicious lamb and prawns.</p>
              </div>
            </Waypoint>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WelcomeSection;
