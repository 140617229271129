import React, { useContext, useEffect, useState } from 'react';

import { GlobalContext } from '../context/GlobalContext';

const SideMenu = () => {

  const context = useContext(GlobalContext);

  const [rightPos, setRightPos] = useState('-102%');

  useEffect(() => {
    context.menuOpen ? setRightPos(0) : setRightPos('-102%');
  }, [context.menuOpen]);

  const closeMenu = () => {
    context.closeMenu();
  }

  return (
    <div id="side-menu" style={{right: rightPos}}>
      <div className="side-menu-inner Nvl-before-bg">
        <nav className="Nvl-side-nav">
          <ul className="Nvl-side-nav-list">
            <li className="Nvl-side-nav-list-item Nvl-list-i1">
              <a onClick={() => closeMenu()} href="#Home" className="Nvl-side-nav-list-link">HOME</a>
            </li>
            <li className="Nvl-nav-side-list-item Nvl-list-i2">
              <a onClick={() => closeMenu()} href="#Locations" className="Nvl-side-nav-list-link">LOCATIONS</a>
            </li>
            <li className="Nvl-side-nav-list-item Nvl-list-i3">
              <a onClick={() => closeMenu()} href="#Menus" className="Nvl-side-nav-list-link">OUR MENUS</a>
            </li>
            <li className="Nvl-side-nav-list-item Nvl-list-i4">
              <a onClick={() => closeMenu()} href="#Gallery" className="Nvl-side-nav-list-link">PHOTOS</a>
            </li>
            <li className="Nvl-side-nav-list-item Nvl-list-i5">
              <a onClick={() => closeMenu()} href="#Contact" className="Nvl-side-nav-list-link">CONTACT</a>
            </li>
            <li className="Nvl-nav-list-item Nvl-list-i5">
              <button
                onClick={() => context.openOrderModal()}
                className="Nvl-nav-list-link Nvl-nav-list-link-cta z-depth-3"
                type="button">
                <i className="akk-icon-food"></i> ORDER ONLINE / BOOK TABLE
              </button>
          </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default SideMenu;
