import React from 'react';

export const GlobalContext = React.createContext({
    pages: null,
    posts: null,
    title: null,
    config: null,
    head_tags: null,
    menuOpen: null,
    loading: true,
    apiData: [],
    orderModal: false,
    orderLocation: null,
    contacts: null,
    setAPiData: () => {},
    setLoading: () => {},
    setStateAsync: () => {},
    setOrderLocation: () => {},
    openOrderModal: () => {},
    closeOrderModal: () => {},
    openMenu: () => {},
    closeMneu: () => {},
    toggleMenu: () => {}
});

export class GlobalProvider extends React.Component {

    state = {
        pages: [],
        posts: [],
        title: 'Saffron Cyprus',
        config: {},
        head_tags: [],
        menuOpen: false,
        loading: true,
        apiData: [],
        orderModal: false,
        orderLocation: '',
        contacts: [
          {
            name: 'messenger',
            link: 'https://www.messenger.com/t/AkakikoCyprus',
            icon: 'akk-icon-messenger'
          },
          {
            name: 'instagram',
            link: 'https://instagram.com/akakikocy',
            icon: 'akk-icon-instagram'
          },
          {
            name: 'facebook',
            link: 'https://www.facebook.com/AkakikoCyprus/',
            icon: 'akk-icon-facebook'
          },
          {
            name: 'twitter',
            link: 'https://twitter.com/AkakikoCY',
            icon: 'akk-icon-twitter'
          },
          {
            name: 'phone',
            link: 'tel:+35777778022',
            icon: 'akk-icon-phone'
          },
          {
            name: 'mail',
            link: 'mailto:info@akakiko.com.cy',
            icon: 'akk-icon-mail'
          }
        ]
    }

    componentWillMount() {
      const fetchData = async () => {
        const resp = await fetch( 'https://api.saffroncyprus.com/wp-json/novelapi/v1/site');
        const data = await resp.json();
        this.setAPiData(data);
      }

      fetchData();
    }

    setAPiData = (data) => {
      this.setState({
        apiData: data
      });
    }

    setLoading = (val) => {
      console.log('setLoading', val)
      this.setState({
          loading: val
      });
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve)
        });
    }

    setOrderLocation = (loc) => {
      this.setState({
        orderLocation: loc
      });
    }

    openOrderModal = () => {
      this.setState({
        orderModal: true
      });
    }

    closeOrderModal = () => {
      this.setState({
        orderModal: false
      });
    }

    openMenu = () => {
      this.setState({ menuOpen: true });
    }

    closeMenu = () => {
      this.setState({ menuOpen: false });
    }

    toggleMenu = () => {
      this.setState((state) => ({
        menuOpen: !state.menuOpen
      }));
      console.log(this.state.menuOpen)
    }

    render() {
        return (
            <GlobalContext.Provider value={{
                ...this.state,
                openOrderModal: this.openOrderModal,
                closeOrderModal: this.closeOrderModal,
                setOrderLocation: this.setOrderLocation,
                openMenu: this.openMenu,
                closeMenu: this.closeMenu,
                toggleMenu: this.toggleMenu,
                setLoading: this.setLoading,
                setAPiData: this.setAPiData,
            }}>
                {this.props.children}
            </GlobalContext.Provider>
        )
    }
}

export const GlobalConsumer = GlobalContext.Consumer;
