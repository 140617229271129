import React, { useState, useEffect } from 'react';
import { Waypoint } from 'react-waypoint';

import { restaurantLocations, colors } from '../helpers';

//
const LocListRow = props => {
  let rowRef = React.createRef();

  const rowEnter = (event) => {
    rowRef.current.classList.add('el-in-view');
  }

  return (
    <div>
      <Waypoint
      scrollableAncestor={window}
      onEnter={rowEnter}>
        <div className="locations-list" ref={rowRef}>
          {props.items.map(loc => (
            <div
              className="location-item z-depth-4"
              key={loc.id}
              onMouseEnter={e => e.currentTarget.classList.add('hovered')}
              onMouseLeave={e => e.currentTarget.classList.remove('hovered')}>
              <figure>
                <img
                  className="Nvl-img-flex"
                  src={loc.img_flip} alt={loc.name} />
                <img
                  className="Nvl-img-flex"
                  src={loc.img}
                  alt={loc.name}/>
              </figure>

              <div className="location-item-info">
                <h3>{loc.name}</h3>
                <p className="Nvl-txt-center"><i style={{color: colors.red}}className="akk-icon-location"></i> {loc.location}</p>
              </div>

              <div className="contact-actions">
                <hr />
                <span className="Nvl-block"><strong>Contact us:</strong></span>
                <br/>
                <a className="Nvl-btn Nvl-btn-link" href={`tel:${loc.tel}`}>
                  <i style={{color: colors.red}} className="akk-icon-phone"></i> {loc.tel}
                </a>
                {'|'}
                <a className="Nvl-btn Nvl-btn-link" href={loc.messenger.link} target="_blank" rel="noopener noreferrer">
                  <i style={{color: colors.red}} className="akk-icon-messenger"></i> @{loc.messenger.handle}
                </a>
              </div>
            </div>
          ))}
        </div>
      </Waypoint>
    </div>
  )
}

//
const LocationsSection = () => {
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    setLocations(restaurantLocations);
  }, []);

  return (
    <section className="Nvl-section Nvl-before-bg" id="Locations">
      <div className="Nvl-container Nvl-txt-center">
        <h3 className="Nvl-section-title">OUR LOCATIONS</h3>
        <LocListRow items={locations.slice(0,3)} />
        <br/>
        <LocListRow items={locations.slice(3)} />
      </div>
    </section>
  )
}

export default LocationsSection;
