import React, { useState } from 'react';

const SiteLoader = () => {
  const [show, setShow] = useState(true);

  setTimeout(() => {
    setShow(false);
  }, 2000);

  return (
    <div id="site-loader" className={show ? 'loading' : 'loaded'} style={{top: show ? 0 : '-110%'}}>
      <div className="site-loader-inner">
        <div className="site-loader-content">
          <img width="200" src="/assets/images/logo-006.svg" alt="Saffron logo" />
        </div>
        <span className="sl-lines sl-lines-l"><span></span></span>
        {/* <span className="sl-lines sl-lines-r"><span></span></span> */}
      </div>
    </div>
  )
}

export default SiteLoader;
