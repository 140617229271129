import React, { useContext } from 'react';

import { GlobalContext } from '../context/GlobalContext';

const Menus = () => {

  const context = useContext(GlobalContext);

  return (
    <section className="Nvl-section Nvl-section-menus" id="Menus">
      <div className="bg-overlay"></div>
      <div className="Nvl-container Nvl-txt-center">
        <h3 className="Nvl-section-title">OUR MENUS</h3>

        <div className="menu-list">
          {context.apiData.menus_list && context.apiData.menus_list.map((m, i) => (
            <a href={m.file.url} target="_blank" rel="noopener noreferrer" key={`menu-${i}`}>
              {m.label}
            </a>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Menus;

