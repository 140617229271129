//
export const fixDecimals = (value, decimals = 2) => {
  return Math.round((value) * Math.pow(10, decimals)) / Math.pow(10, decimals);
}

//
export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

//
export const elInView = (el) => {
  let rect = el.getBoundingClientRect();
  let elemTop = rect.top;
  let elemBottom = rect.bottom;

  // Only completely visible elements return true:
  let isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
  // Partially visible elements return true:
  //isVisible = elemTop < window.innerHeight && elemBottom >= 0;
  return isVisible;
}

//
export const arrChunk = (arr, size = 2) => {
  return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  );
}

//
export const restaurantLocations = [
  {
    id: 1,
    location: 'Eleftherias 50, 3042 Limassol, Cyprus',
    tel: 96293737,
    orderLink: 'orderlink',
    name: 'Saffron Indian Limassol',
    img: '/assets/images/saffron_limassol.jpg',
    img_flip: '/assets/images/limassol_flip.jpg',
    cuid: null,
    ruid: null,
    lat: 34.6741636,
    lng: 33.0384986,
    order: 'https://foody.com.cy/gr/menu/saffron',
    book: true,
    map: 'https://goo.gl/maps/tRQXmxD4CUzujD226',
    messenger: {
      handle: 'SaffronLimassol',
      link: 'https://m.me/SaffronLimassol',
    }
  },
  {
    id: 2,
    location: '64 Ampelonon Street, 4607 Pissouri bay, Cyprus ',
    tel: 96778084,
    orderLink: 'orderlink',
    name: 'Saffron Indian Pissouri',
    img: '/assets/images/pissouri.jpg',
    img_flip: '/assets/images/pissouri_flip.jpg',
    cuid: null,
    ruid: null,
    lat: 34.651333,
    lng: 32.724049,
    order: null,
    opentable: 'https://www.opentable.com/r/saffron-pissouri',
    map: 'https://goo.gl/maps/ixbjuvHNJuSsf4DG6',
    messenger: {
      handle: 'SaffronPissouri',
      link: 'https://m.me/SaffronPissouri',
    }
  },
];

export const colors = {
  red: '#d00929',
  black: '#222',
  lightGrey: '#eeefea'
}

export const loadScript = (url) => {
  let index = window.document.getElementsByTagName('script')[0];
  let script = window.document.createElement('script');
  script.src = url;
  script.async = true;
  script.defer = true;
  document.body.appendChild(script);
  index.parentNode.insertBefore(script, index);
}


export const pageScrolled = (event, offset = 200) => {

  let isScrolled = false;

  if (window.pageYOffset >= offset && !isScrolled) {
    document.body.classList.add('page-scrolled');
    isScrolled = true;
  }

  if(window.pageYOffset < offset) {
    document.body.classList.remove('page-scrolled');
    isScrolled = false;
  }
}
